#error-page {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.85);
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	z-index: 10000000;
	color: white;
	flex-direction: column;
	display: none;
}

#error-page .error-inner {
	color: white;
	display: flex;
	background-image: url("./tile.png");
	background-repeat: repeat;
	border-radius: 5px;
	padding: 20px;
	position: relative;
	width: 350px;
	border: 10px solid rgba(0,0,0,0);
	border-image: url("./bruh-panel.png");
	border-image-slice: 18%;
	border-image-width: 12px;
}

#error-page .error-inner #error-close {
	position: absolute;
	cursor: pointer;
	top: -30px;
	right: -30px;
}

#error-page .error-inner #error-inner-text {
	text-align: center;
}